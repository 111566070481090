import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"

const About = () => (
  <Layout>
    <Seo title="About Us" />
    <Banner title="About Us" />
    <section id="services" className="single-features pt-5 pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <p className="pt-2 pb-2 lead">
              Lappy Nation was established in the year 2020, headquartered in
              Gurugram, Haryana. With the most recent headway in innovations,
              the entire world is getting digitized. We at ‘Lappy Nation’ goes
              about as some assistance simultaneously. We provide innovative
              solutions for current problems in varied fields keeping an eye on
              future challenges and issues.
            </p>

            <p className="pt-2 pb-2 lead">
              Lappy Nation is one of the developing Information Technology
              Company, giving a wide scope of administrations from Students to
              Business, from Scholars to professionals both for huge and little
              ventures and public area endeavors. We offer rentals on various IT
              products such as Laptops, Desktops, Printers, Security Systems-
              CCTVs and many more.
            </p>

            <p className="pt-2 pb-2 lead">
              The organization tries to develop by executing explicit ventures
              nearby Computer Rentals, Laptop Rentals. We offer our services in
              Gurugram as well in Delhi NCR covering Delhi, Faridabad, Noida.
            </p>

            <p className="pt-2 pb-2 lead">
              As a customer centric organization we do believe that client
              satisfaction is utmost important. Our central goal is to give
              prevalent items at moderate rentals and that’s why fit in all
              business categories.
            </p>

            <p className="pt-2 pb-2 lead">
              We take into account the necessities of corporate occasions,
              workshops, gatherings, official gatherings, and so forth in
              Delhi-NCR. Joining the most elevated level of administration with
              the most forefront innovation, the Lappy Nation is prepared to
              help you.
            </p>

            <p className="pt-2 pb-2 lead">
              Our current clients rehash business and keep on benefiting from
              our services. Our quality items, most recent brands, and very much
              oversaw stock give the most significant level of consumer loyalty.
            </p>

            <p className="pt-2 pb-2 lead">
              We have a team of dedicated experts that provide world class
              services at most affordable prices. Our Team would without a doubt
              go that additional mile to fulfill your time constraints. When in
              doubt we generally attempt to satisfy our clients’ needs according
              to their necessities.
            </p>

            <p className="pt-2 pb-2 lead">
              With the forthcoming present day Business methodologies, the
              requirement for Information Technology types of gear has expanded
              to convey the idea with most recent devices and deal with the
              organizations/occasions according to the assumptions and
              standards.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About
