import * as React from "react"
import { Carousel } from "react-bootstrap"
import "./banner.css"

const Banner = props => (
  <>
    <section className="banner-area">
      <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="slider-content text-center">
                  <h1 className="title text-uppercase">{props.title}</h1>
                  {props.subtitle && <p className="text">{props.subtitle}</p>}
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  </>
)

export default Banner
